let benefitsCarousel = new Swiper(".benefitsCarousel", {
    slidesPerView: 1,
    spaceBetween: 24,
    loop: false,
    speed: 800,
    autoplay: {
        delay: 7000,
    },
    mousewheel: false,
    grabCursor: true,
    effect: "creative",
    creativeEffect: {
        prev: {
            translate: ["-120%", 0, -1],
            scale: [.64],
            opacity: [.24]
        },
        next: {
            translate: ["120%", 0, 0],
            scale: [.64],
            opacity: [.24]
        },
    },
    progressbar: '.swiper-progressbar-benefits',
});

let projectsCarousel = new Swiper(".projectsCarousel", {
    breakpoints: {
        0: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
        1536: {
            slidesPerView: 4,
        },
    },
    spaceBetween: 0,
    loop: false,
    speed: 800,
    autoplay: false,
    mousewheel: false,
    grabCursor: false,
    progressbar: '.swiper-progressbar',
});

let commentsCarouselThumbs = new Swiper(".commentsCarouselThumbs", {
    slidesPerView: "auto",
    spaceBetween: -24,
    freeMode: true,
    loop: false,
    speed: 800,
    autoplay: {
        delay: 7000,
    },
});
let commentsCarousel = new Swiper(".commentsCarousel", {
    slidesPerView: 1,
    spaceBetween: 48,
    autoHeight: true,
    loop: false,
    speed: 800,
    autoplay: {
        delay: 7000,
    },
    thumbs: {
        swiper: commentsCarouselThumbs,
    },
    // direction: "vertical",
    grabCursor: true,
});

let customerCarousel = new Swiper(".customerCarousel, .customerCarousel2", {
    breakpoints: {
        0: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    },
    centeredSlides: true,
    spaceBetween: 0,
    loop: true,
    speed: 8000,
    // allowTouchMove: false,
    freeModeMomentum: false,
    autoplay: {
        delay: 1000,
        disableOnInteraction: true
    },
});
// TODO Dynamic resize will be tested
// projectsCarousel.on('resize', () => {
//     console.log('changed');
// });

let featuredCarousel = new Swiper(".featuredCarousel", {
    slidesPerView: 1,
    spaceBetween: 24,
    loop: false,
    speed: 800,
    autoplay: {
        delay: 7000,
    },
    mousewheel: false,
    grabCursor: false,
    effect: "creative",
    creativeEffect: {
        prev: {
            translate: ["-120%", 0, -1],
            scale: [.64],
            opacity: [.24]
        },
        next: {
            translate: ["120%", 0, 0],
            scale: [.64],
            opacity: [.24]
        },
    },
    progressbar: '.swiper-progressbar-featured',
});

let projectsDetailCarousel = new Swiper(".projectsDetailCarousel", {
    breakpoints: {
        0: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
        1536: {
            slidesPerView: 4,
        },
    },
    spaceBetween: 24,
    loop: false,
    speed: 800,
    autoplay: {
        delay: 7000,
    },
    mousewheel: false,
    grabCursor: false,
    progressbar: '.swiper-progressbar-projects-detail',
});

const setProgress = (querySelector, activeIndex, length) => {
    const progress = (((activeIndex + 1) / length) * 100).toFixed(1);

    document.querySelectorAll(querySelector).forEach(el => el.style.width = progress + '%');
}

const customProgress = (...slides) => {
    slides.forEach((slide) => {
        if (!slide.currentBreakpoint && !slide.originalParams.slidesPerView) return;

        let slidesPerView = slide.originalParams.slidesPerView;

        if (slide.currentBreakpoint && slide.originalParams.breakpoints && slide.originalParams.breakpoints[slide.currentBreakpoint]) {
            slidesPerView = slide.originalParams.breakpoints[slide.currentBreakpoint]?.slidesPerView;
        }

        if (isNaN(slidesPerView)) {
            return;
        }

        const extraSlides = slidesPerView !== 1 ? slidesPerView - 1 : 0;

        const length = slide.slides.length - extraSlides;


        setProgress(slide.originalParams.progressbar, slide.activeIndex, length)

        slide.on('activeIndexChange', () => {
            setProgress(slide.originalParams.progressbar, slide.activeIndex, length)
        });
    })
}

customProgress(projectsCarousel);
customProgress(benefitsCarousel);
customProgress(featuredCarousel);
customProgress(projectsDetailCarousel);
